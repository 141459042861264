








import {Component, Prop, Vue} from 'vue-property-decorator'
import {MetaInfo} from 'vue-meta'
import DappsOverview from '@/components/DappsOverview.vue'

@Component({
  components: {
    DappsOverview,
  },
})
export default class AppsOverviewView extends Vue {
  @Prop({type: String, default: null}) privateCategorySlug!: string | null

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.appsOverview'),
    }
  }
}
