






































































































































































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {DappInfo} from '@/model/resource/DappInfo'
import {DappSocial} from '@/model/resource/DappSocial'
import NetworkTag from '@/components/NetworkTag.vue'
import InactiveTag from '@/components/InactiveTag.vue'

@Component({
  components: {InactiveTag, NetworkTag, PercentageProgress},
})
export default class DappCard extends Mixins(MixinScreenSize) {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean}) isBasic?: boolean
  @Prop({type: Boolean}) showWebsite?: boolean
  @Prop({type: Boolean}) rowMode?: boolean
  @Prop({type: Boolean}) showTags?: boolean
  @Prop({type: Boolean, default: true}) showVersion?: boolean
  @Prop({type: Boolean, default: false}) isLastDayTransactionsInfo?: boolean
  @Prop({type: Boolean, default: true}) showPercentage?: boolean
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  dappInfo = new DappInfo()
  socials: DappSocial[] | null = null
  showMobileLinksMenu = false

  get dappCardClass() {
    return {
      'dapp-card--row': this.rowMode,
      'dapp-card--center': this.isBasic,
    }
  }

  @Watch('dapp', {immediate: true, deep: true})
  async populateResources() {
    if (!this.showWebsite || !this.dapp.slug || !this.dapp.$id) return
    const promises: Array<Promise<unknown>> = [
      this.dappInfo.getInfoDapp(this.dapp.slug),
    ]

    await Promise.all(promises)

    if (this.dappInfo.socials != null) {
      this.socials = this.dappInfo.socials.filter((_social, index) => index < 4)
    }
  }
}
